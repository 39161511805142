"use client";

import { captureExceptionsWithSentry } from "@common/utils/error";
import NextError from "next/error";
import { useEffect } from "react";
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    console.error(error);
    captureExceptionsWithSentry(error);
  }, [error]);
  return <html lang="en" data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
			<body>
				{/* `NextError` is the default Next.js error page component. Its type
        definition requires a `statusCode` prop. However, since the App Router
        does not expose status codes for errors, we simply pass 0 to render a
        generic error message. */}
				<NextError statusCode={0} data-sentry-element="NextError" data-sentry-source-file="global-error.tsx" />
			</body>
		</html>;
}